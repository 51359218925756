import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles(({ typography }) => ({
	requestTitle: {
		...typography.title,
		lineHeight: "44px",
		marginTop: 8,
	},
	requestSubtitle: {
		margin: "4px 0 18px 0",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		margin: "10px 10px 25px",
		height: "100%",
	},
}));
