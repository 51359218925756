import React, {
	useContext,
	createContext,
	useEffect,
	useState,
	ChangeEvent,
	useCallback,
} from "react";

import type { IdContextType } from "@id/types";

import { useIdLeads, Status } from "@api/id";
import { useAuth } from "@api/auth";
import { useViewContext } from "~/state/view";

import RequestIdVerificationForm from "@id/forms";

const defaultContext: IdContextType = {
	page: 0,
	error: "",
	companyId: 0,
	loading: false,
	changePage: (event, page) => {
		/* dummy Fb */
	},
	showRequestIdVerificationModal: (props) => {
		/* dummy Fb */
	},
	treeCount: 0,
	count: 0,
};

const IdContext = createContext<IdContextType>(defaultContext);

export const useIdContext = () => {
	const context = useContext(IdContext);
	if (!context) {
		throw new Error(
			"You cannot use IdContext from outside of its Provider",
		);
	}

	return context;
};

const useIdContextValue = () => {
	const [companyId, setCompanyId] = useState(0);
	const [page, setPage] = useState(1);
	const { selectedCompany } = useAuth();
	const changePage = (event: ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	useEffect(() => {
		selectedCompany && setCompanyId(selectedCompany.id);
	}, [selectedCompany]);

	const { count } = useIdLeads({
		companyId: companyId,
		status: Status.COMPLETED,
	});
	const treesPlanted = count ? Math.floor(count * (3 / 100)) : 0;

	const {
		actions: { openModal: showLayoutModal, closeModal: closeLayoutModal },
	} = useViewContext();

	const showRequestIdVerificationModal = useCallback(
		(props) => {
			showLayoutModal({
				content: (
					<RequestIdVerificationForm
						{...props}
						onComplete={closeLayoutModal}
					/>
				),
				props: {
					title: props.action,
				},
			});
		},
		[showLayoutModal, closeLayoutModal],
	);

	return {
		error: "",
		page,
		companyId: companyId,
		loading: false,
		count: count || 0,
		treeCount: treesPlanted,
		changePage,
		showRequestIdVerificationModal,
	};
};

export const IdProvider: React.FC = ({ children }) => {
	const contextValue = useIdContextValue();

	return (
		<IdContext.Provider value={contextValue}>{children}</IdContext.Provider>
	);
};
