import React from "react";

import { Box } from "@material-ui/core";

import { customStyles } from "./styles";

interface ITemplates {
	template: string;
}

const getTemplate = (template: string) => {
	switch (template) {
		case "Front":
			return ["Photo Id"];
		case "FrontBack":
			return ["Photo Id"];
		case "FrontSelfie":
			return ["Photo Id", "Selfie"];
		case "FrontBackSelfie":
			return ["Photo Id", "Selfie"];
		case "FrontSelfieLiveness":
			return ["Photo Id", "Selfie", "Biometric"];
		case "FrontBackSelfieLiveness":
			return ["Photo Id", "Selfie", "Biometric"];
		default:
			return [];
	}
};

const TemplateLabels = ({ template }: ITemplates) => {
	const styles = customStyles();
	const templateList = getTemplate(template);
	return (
		<Box className={styles.templatesContainer}>
			{templateList.map((template: string) => {
				return (
					<span className={styles.template} key={template}>
						{template}
					</span>
				);
			})}
		</Box>
	);
};

export default TemplateLabels;
