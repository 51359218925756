import React, { Fragment, useMemo } from "react";

import Popover from "~/components/Popover";

import { customStyles } from "./styles";

import Checkpoint from "~/assets/vectors/checkpoint-icon.svg";

import { LeadJourney } from "@api/graphql/types";

import { ICheckpoints } from "./types";

const Checkpoints = ({ journeyStages }: ICheckpoints) => {
	const styles = customStyles();

	const formatStepName = (step: string) => {
		switch (step) {
			case "ID_SMS_SENT":
				return "Sms Sent";
			case "ID_LINK_OPENED":
				return "Link Opened";
			case "ID_INITIATED":
				return "Initiated";
			case "ID_COMPLETE":
				return "Completed";
			default:
				return "";
		}
	};

	const content = useMemo(() => {
		const steps = [
			"ID_SMS_SENT",
			"ID_LINK_OPENED",
			"ID_INITIATED",
			"ID_COMPLETE",
		];
		const isJourneyStageCompleted = (journey: string) => {
			const completed = journeyStages.find(
				(j: LeadJourney) => j?.stage === journey,
			);
			if (completed) {
				return true;
			}
			return false;
		};

		const isAnyNextStepCompleted = (index: number, elements: string[]) => {
			for (let i = index + 1; i < elements.length; i++) {
				if (isJourneyStageCompleted(elements[i])) {
					return true;
				}
			}
			return false;
		};

		return (
			<div className={styles.checkpointsContainer}>
				{steps.map(
					(step: string, index: number, elements: string[]) => {
						const anyNextStepCompleted = !!isAnyNextStepCompleted(
							index,
							elements,
						);
						const completedJourney = isJourneyStageCompleted(step);

						const renderSteps = () => {
							if (completedJourney || anyNextStepCompleted) {
								return <Checkpoint />;
							}
							return index + 1;
						};

						return (
							<Fragment key={step}>
								<span
									style={{
										backgroundColor:
											!completedJourney &&
											!anyNextStepCompleted
												? "#adb5bd"
												: "",
									}}
									className={styles.checkpoint}
								>
									<Popover text={formatStepName(step)}>
										{renderSteps()}
									</Popover>
								</span>
							</Fragment>
						);
					},
				)}
			</div>
		);
	}, [journeyStages, styles.checkpoint, styles.checkpointsContainer]);

	return <div className={styles.checkpointsContainer}>{content}</div>;
};

export default Checkpoints;
