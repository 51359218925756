import React from "react";

import dayjs from "dayjs";

import { InfoCard } from "~/components/InfoCard";
import { ITableColumn, ITableRow, Table } from "~/components/Table";

import Checkpoints from "../Checkpoints";
import Status from "../Status";
import TemplateLabels from "../TemplateLabels";

import Options from "../Options";

import { Lead, LeadJourney } from "@api/graphql/types";

import { IIdListTable } from "./types";

import { navigate } from "@utils/navigate";

export const idListTable: ITableColumn[] = [
	{
		label: "Full Name",
		property: "",
		width: "auto",
		cell(props) {
			const fullName = [props.firstName, props.lastName].join(" ");
			return (
				<div style={{ cursor: "pointer" }} onClick={() => navigate(`/leads/${props.id}/`, { savePath: true })}>{fullName}</div>
			);
		},
	},
	{
		label: "Phone",
		property: "phoneNumber",
		width: "auto",
	},
	{
		label: "Steps Completed",
		property: "leadJourneys",
		cell(props) {
			return (
				<Checkpoints journeyStages={props?.nodes} />
			);
		},
	},
	{
		label: "Verifications",
		property: "",
		cell(props) {
			return (
				<TemplateLabels template={props} />
			);
		},
	},
	{
		label: "Status",
		property: "",
		cell(props) {
			return (
				<Status status={props?.retrieveIdVerification?.action} />
			);
		},
	},
	{
		label: "Last Activity",
		property: "",
	},
	{
		label: "",
		property: "",
		cell(props) {
			return (
				<Options leadId={props.id} />
			);
		},
	},
];

const IdListTable = ({ loading, data }: IIdListTable) => {
	const rows: ITableRow[] =
		data?.map((node: Lead) => {
			const lastJourneyUpdated = node?.leadJourneys?.nodes?.reduce(
				(a: LeadJourney, b: LeadJourney) =>
					a.updatedAt > b.updatedAt ? a : b,
			);
			const fromNow = dayjs(lastJourneyUpdated?.updatedAt).fromNow();
			return {
				id: node.id,
				values: [
					node,
					node.phoneNumber,
					node.leadJourneys,
					node.idVerificationTemplateType,
					node,
					fromNow,
					node,
				],
			} as ITableRow;
		}) || [];

	return (
		<InfoCard loading={loading}>
			<Table columns={idListTable} rows={rows || []} loading={loading} />
		</InfoCard>
	);
};

export default IdListTable;
