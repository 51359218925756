import React, { Fragment, useEffect, useMemo, useState } from "react";

import { useAuth } from "@api/auth";
import { Status, useIdLeads } from "@api/id";
import { ProductType } from "@api/graphql/types";

import { useIdContext } from "../context";

import { isProductEnabled } from "@utils/products";

import { ControlBar } from "@id/components/ControlBar";
import IdListTable from "@id/components/Table";
import RequestIdCta from "@id/components/RequestIdCta";
import { CriticalError } from "@id/components/CriticalError";
import Pager from "@id/components/Pager";

import { useSearch } from "~/components/Search";
import { ProductCtaCard } from "~/components/ProductCta/Card";
import TOSModal from "~/components/TOSModal";

import { Box } from "@material-ui/core";

import { customStyles } from "./styles";

export const PAGE_SIZE = 10;

const IdVerificationDisplay = () => {
	const { search } = useSearch();
	const { selectedCompany } = useAuth();
	const styles = customStyles();

	const { companyId, page, changePage } = useIdContext();

	const [status, setStatus] = useState<Status>(Status.ALL);

	const { data, error, loading, count } = useIdLeads({
		companyId,
		limit: PAGE_SIZE,
		offset: (page - 1) * PAGE_SIZE,
		search,
		status: status,
	});

	const isIdToolEnabled = isProductEnabled(selectedCompany, ProductType.IdTool);

	const content = useMemo(() => {
		if (!selectedCompany) {
			return <CriticalError message="Ooops! No company selected!" error={error} />;
		}

		if (!isIdToolEnabled) {
			return <ProductCtaCard style={{ marginLeft: 20, marginRight: 20 }} topic="ID_TOOL" />;
		}

		if (!loading && !data?.length && !search) {
			return (
				<Fragment>
					<RequestIdCta />;
					<TOSModal company={selectedCompany} topic={ProductType.IdTool} loadingData={loading} />
				</Fragment>
			);
		}

		const handleClick = (event: React.ChangeEvent) => {
			changePage(event, 1);
			if (status === Status.ALL) {
				setStatus(Status.COMPLETED);
			} else {
				setStatus(Status.ALL);
			}
			return null;
		};

		return (
			<Box className={styles.displayContent}>
				<ControlBar status={status} onChangeStatus={handleClick} />
				<IdListTable data={data} loading={loading} />
				<Pager count={count} limit={PAGE_SIZE} />
				<TOSModal company={selectedCompany} topic={ProductType.IdTool} loadingData={loading} />
			</Box>
		);
	}, [changePage, count, data, error, isIdToolEnabled, loading, search, selectedCompany, status, styles.displayContent]);
	return (
		<Fragment>
			{content}
		</Fragment>
	);
};

export default IdVerificationDisplay;
