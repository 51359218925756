import React from "react";

import { useIdContext } from "@id/context";

import { Box, Typography } from "@material-ui/core";

import { PrimaryButton } from "~/components/Button";

import RequestImage from "~/assets/vectors/request-id.svg";

import { customStyles } from "./styles";

const RequestIdCta: React.FC = () => {
	const { showRequestIdVerificationModal } = useIdContext();
	const styles = customStyles();

	return (
		<Box className={styles.container}>
			<RequestImage />
			<Typography className={styles.requestTitle}>
				No Verified ID’s yet!{" "}
			</Typography>
			<Typography className={styles.requestSubtitle}>
				Send out your first ID verification request
			</Typography>
			<PrimaryButton
				onClick={() =>
					showRequestIdVerificationModal({ action: "Request ID" })
				}
			>
				<Typography variant="inherit">Request ID</Typography>
			</PrimaryButton>
		</Box>
	);
};

export default RequestIdCta;
