import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
	return {
		container: {
			display: "flex",
			flexFlow: "row nowrap",
			justifyContent: "space-between",
			alignItems: "center",
			[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
				padding: 0,
			},
		},
		topSubNavRight: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			gap: 12,
			[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
				display: "none",
			},
		},
		tree: {
			display: "flex",
			alignItems: "center",
			gap: 4,
			border: "1px solid #D8D8D9",
			borderRadius: 14,
			padding: "8px 14px",
			cursor: "pointer",
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				display: "none",
			},
		},
		popover: {
			display: "flex",
			flexDirection: "column",
			gap: 12,
			marginTop: 6,
			padding: 16,
			backgroundColor: "#FFFFFF",
			border: "1px solid #CDCDCD",
			borderRadius: 14,
		},
		popoverItalic: {
			fontStyle: "italic",
		},
		popoverSubtitle: {
			fontWeight: "bold",
		},
		requestText: {
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				fontSize: 14,
			},
		},
	};
});
