import React, { Fragment } from "react";

import { Typography } from "~/components/Typography";

// import Completed from "~/assets/vectors/completed.svg";
import Rejected from "~/assets/vectors/rejected.svg";
import InProgress from "~/assets/vectors/in-progress.svg";

import { customStyles } from "./styles";

export const getStatus = (status: string, boldText?: boolean) => {
	const styles = customStyles();
	switch (status) {
		case "accept":
			return (
				<div className={styles.status}>
					<InProgress />
					<Typography style={{ fontWeight: boldText ? "bold" : "" }}>
						Capture Only (No verification)
					</Typography>
				</div>
			);

		// return (
		// 	<div className={styles.status}>
		// 		<Completed />
		// 		<Typography style={{ fontWeight: boldText ? "bold" : "" }}>
		// 			Verified
		// 		</Typography>
		// 	</div>
		// );
		case "reject":
			return (
				<div className={styles.status}>
					<Rejected />
					<Typography style={{ fontWeight: boldText ? "bold" : "" }}>
						Rejected
					</Typography>
				</div>
			);
		case "review":
			return (
				<div className={styles.status}>
					<InProgress />
					<Typography style={{ fontWeight: boldText ? "bold" : "" }}>
						Undetermined
					</Typography>
				</div>
			);
		default:
			return (
				<div className={styles.status}>
					<Typography style={{ fontWeight: boldText ? "bold" : "" }}>
						In-Progress
					</Typography>
				</div>
			);
	}
};

interface IStatus {
	status: string;
}

const Status = ({ status }: IStatus) => {
	return <Fragment>{getStatus(status)}</Fragment>;
};

export default Status;
