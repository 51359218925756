import React from "react";

import { useIdContext } from "../../context";

import { Search } from "~/components/Search";
import { PrimaryButton } from "~/components/Button/PrimaryButton";
import { Typography } from "~/components/Typography";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/styles";
import { FormControlLabel, Switch } from "@material-ui/core";

import Tree from "~/assets/vectors/tree.svg";

import { IControlBar } from "./types";

import { useStyles } from "./styles";
import RequestIdMobile from "../RequestIdMobile";

export const ControlBar = ({ status, onChangeStatus }: IControlBar) => {
	const styles = useStyles();
	const { treeCount, count } = useIdContext();
	const { showRequestIdVerificationModal } = useIdContext();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "popover-tree" : undefined;
	const left = Math.ceil(100 / 3) - (count % 33);

	const Label = withStyles((theme) => ({
		label: theme.typography.inputLabel,
	}))(FormControlLabel);

	return (
		<Box className={styles.container}>
			<Search />
			<RequestIdMobile
				onClick={() =>
					showRequestIdVerificationModal({ action: "Request ID" })
				}
			/>
			<Box className={styles.topSubNavRight}>
				<Label
					control={
						<Switch
							checked={status === "COMPLETED"}
							onChange={onChangeStatus}
						/>
					}
					labelPlacement="start"
					label="Only Completed"
				/>
				<PrimaryButton
					type="submit"
					onClick={() =>
						showRequestIdVerificationModal({ action: "Request ID" })
					}
				>
					<Typography className={styles.requestText}>
						Request ID
					</Typography>
				</PrimaryButton>
				<Box>
					<Box
						aria-describedby={id}
						onClick={handleClick}
						className={styles.tree}
					>
						<Tree />
						<Typography>{treeCount}</Typography>
					</Box>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
					>
						<Box className={styles.popover}>
							<Typography>
								This is the number of trees you have planted
								just by using ID.
							</Typography>
							<Typography className={styles.popoverItalic}>
								Only {left} ID verifications left to plant your
								next tree.
							</Typography>
							<Typography className={styles.popoverSubtitle}>
								Keep up the good work!
							</Typography>
						</Box>
					</Popover>
				</Box>
			</Box>
		</Box>
	);
};
