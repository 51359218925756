import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import { SecondaryButton } from "~/components/Button";

import CopyLinkIcon from "~/assets/vectors/copy-link.svg";

const useStyles = makeStyles(() => {
	return {
		copyIdText: {
			marginLeft: 8,
		},
		buttonContent: {
			display: "flex",
			justifyContent: "center",
			minWidth: 128,
			gap: 8,
		},
	};
});

interface IClipBoardButtonProps {
	copyText: string;
	className?: string;
	disabled?: boolean;
}

export async function copyTextToClipboard(text: string) {
	if ("clipboard" in navigator) {
		return await navigator.clipboard.writeText(text);
	} else {
		return "";
	}
}

export const ClipboardCopyButton = ({
	copyText,
	className,
	disabled,
}: IClipBoardButtonProps) => {
	const styles = useStyles();
	const [copied, setCopied] = useState(false);

	const handleCopyClick = () => {
		copyTextToClipboard(copyText);
		try {
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<SecondaryButton
			onClick={handleCopyClick}
			className={className}
			disabled={disabled}
		>
			<Box className={styles.buttonContent}>
				<CopyLinkIcon />
				<Typography variant="body1">
					{copied ? "ID Copied!" : "Copy ID Link"}
				</Typography>
			</Box>
		</SecondaryButton>
	);
};

interface IClipBoardIconProps {
	copyText: string;
	className?: string;
}

export const ClipboardCopyIcon = ({
	copyText,
	className,
}: IClipBoardIconProps) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [textCopied, setTextCopied] = useState(false);

	const onMouseOver = () => {
		if (!showTooltip) setShowTooltip(true);
	};

	const onMouseLeave = () => {
		const timeout = textCopied ? 1000 : 0;
		setTimeout(() => {
			setShowTooltip(false);
		}, timeout);
	};

	const copyHandler = () => {
		copyTextToClipboard(copyText);
		setTextCopied(true);
		setTimeout(() => {
			setShowTooltip(false);
		}, 1500);
		setTimeout(() => {
			setTextCopied(false);
		}, 1650);
	};
	return (
		<Tooltip
			title={textCopied ? "Copied!" : "Copy"}
			placement="left"
			open={showTooltip}
			disableHoverListener
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
		>
			<IconButton
				className={className}
				size="small"
				onClick={copyHandler}
			>
				<FileCopyOutlinedIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};
