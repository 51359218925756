import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => {
	return {
		status: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			gap: 4,
			[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
				justifyContent: "flex-start",
			},
		},
	};
});
