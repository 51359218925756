import React from "react";

import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";

import { useIdContext } from "../../context";

import { PagingProps } from "./types";

const useStyles = makeStyles(() => ({
	paging: {
		alignSelf: "end",
	},
}));

const Pager: React.FC<PagingProps> = ({ count, limit }) => {
	const styles = useStyles();
	const { changePage, page } = useIdContext();
	const pageCounter = count && limit ? Math.ceil(count / limit) : 0;
	return pageCounter && pageCounter > 1 ? (
		<Box className={styles.paging}>
			<Pagination page={page} count={pageCounter} onChange={changePage} />
		</Box>
	) : null;
};

export default Pager;
