import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	root: {
		padding: 5,
		boxShadow: "none",
		maxWidth: 375,
	},
	button: {
		transition: "none",
		"&:disabled": {
			color: "#FFFFFF",
		},
	},
	additionalInformationForm: {
		margin: 12,
	},
	additionalInformationLabel: {
		fontSize: 16,
	},
}));
