import React from "react";

import { ViewComponent, createPrivateView } from "~/model/view/private";
import { IdProvider } from "./_lib/context";
import IdVerificationDisplay from "./_lib/Display";

export const IdVerification: ViewComponent = () => {
	return (
		<IdProvider>
			<IdVerificationDisplay />
		</IdProvider>
	);
};

export default createPrivateView(IdVerification, {
	title: "ID Verification",
});
