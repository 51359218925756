import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => {
	return {
		checkpointsContainer: {
			display: "flex",
			gap: 4,
			position: "relative",
		},
		checkpoint: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "50%",
			height: 24,
			width: 24,
			color: "white",
			backgroundColor: "#3E55CD",
		},
		popover: {
			padding: "6px 16px",
			backgroundColor: "#FFFFFF",
			border: "1px solid #CDCDCD",
			borderRadius: 14,
		},
	};
});
