import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => {
	return {
		templatesContainer: {
			display: "flex",
			gap: 4,
		},
		template: {
			display: "flex",
			padding: "4px 16px",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: 12,
			height: 24,
			color: "white",
			backgroundColor: "#3E55CD",
			fontFamily: "'Baloo Bhai 2', 'sans-serif'",
			fontSize: 12,
			fontWeight: 600,
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				height: "auto",
			},
		},
	};
});
